import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function GeownReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Geown Developers</h4>
            
            <p className="mb-0">
                <p className="AboutExpoPara">Geown Casper Properties Pvt Ltd & Geown Casper Estates LLP is started by Mr. Dhiraj Kumar, Mr. Mahesh Sudhakaran, Mr. Vikas Bansal, and Mr. Amit Gupta in Aug 2012 as a real estate company focusing on construction projects. The promoters bring in a wealth of experience with a combined experience in Civil Engineering, Global Sales and Marketing, High-End Information Technology, and Telecommunication. It is a rare and unique combination in the real estate sector. The team is thus endowed with the ability to understand the requirement of highly discerning customers and deliver to their expectations.</p>
            </p>

            {isShowMore && (
                
                 <p className="mb-0">
                    <h6>Our Mission</h6>
                <p className="AboutExpoPara">To build smart homes with the highest standards of professionalism, customer service, and quality. We intend to bring great value to our customers by the way of creating quality living spaces and responding to their needs with a professional approach in all our dealings.
</p>
                <h6>Our Philosophy</h6>
                <p className="AboutExpoPara">We attribute our success to an unwavering commitment to customer satisfaction. By focusing on the best interests of our clients, we believe in building long-term relationships and trust among our high-profile customers. The company’s team strives to consistently deliver results for its clients with total integrity and transparency</p>

                <h6>Our Vision</h6>
                <p className="AboutExpoPara">Empowered by the vision of providing global-class living spaces, Geown is all about helping people to find their kind of property. Tastes, interests, and aspirations dictate the living space each one of us is in search of. Recognizing this deep-felt need, Geown provides the basics for discovering the one right for you in every possible way.
</p>


            </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default GeownReadMore;